<template>
  <el-col :span="24">
    <pz-title>
      <p slot="title">
        DATOS DEL SOLICITANTE
      </p>
    </pz-title>
    <el-row
      class="center-block"
      :gutter="20"
    >
      <el-col
        :offset="1"
        :span="22"
      >
        <el-form
          :ref="reference"
          :model="formulario1"
          status-icon
          :rules="rules_formulario1"
        >
          <el-col
            :xl="{span: 12}"
            :lg="{span: 12}"
            :md="{span: 12}"
            :sm="{span: 24}"
            :xs="{span: 24}"
          >
            <el-form-item
              prop="nombres"
              :error="errors.get('nombres')"
            >
              <el-col :span="24">
                <el-input
                  ref="nombres"
                  v-model="formulario1.nombres"
                  :type="'text'"

                  placeholder="Nombre Completo"
                />
              </el-col>
            </el-form-item>
            <el-form-item
              prop="numero_documento"
              :error="errors.get('numero_documento')"
            >
              <el-col :span="24">
                <el-input
                  ref="numero_documento"
                  v-model="formulario1.numero_documento"
                  :type="'text'"

                  placeholder="C.I"
                />
              </el-col>
            </el-form-item>
            <el-form-item
              prop="provincia"
              :error="errors.get('provincia')"
            >
              <el-col :span="24">
                <el-select
                  ref="provincia"
                  v-model="formulario1.provincia"
                  placeholder="Departamento"
                >
                  <el-option
                    v-for="item in departamentos"
                    :key="item.departamento"
                    :label="item.departamento"
                    :value="item.departamento"
                  />
                </el-select>
              </el-col>
            </el-form-item>
          </el-col>
          <!--rigth-->
          <el-col
            :xl="{span: 12}"
            :lg="{span: 12}"
            :md="{span: 12}"
            :sm="{span: 24}"
            :xs="{span: 24}"
            class="margin"
          >
            <el-form-item
              prop="apellidos"
              :error="errors.get('apellidos')"
            >
              <el-col :span="24">
                <el-input
                  ref="apellidos"
                  v-model="formulario1.apellidos"
                  :type="'text'"

                  placeholder="Apellidos"
                />
              </el-col>
            </el-form-item>

            <el-form-item
              prop="telefono"
              :error="errors.get('telefono')"
            >
              <el-col :span="24">
                <el-input
                  ref="telefono"
                  v-model="formulario1.telefono"
                  :type="'text'"

                  placeholder="Teléfono"
                />
              </el-col>
            </el-form-item>

            <el-form-item
              prop="email"
              :error="errors.get('email')"
            >
              <el-col :span="24">
                <el-input
                  ref="email"
                  v-model="formulario1.email"
                  type="text"
                  auto-complete="no"
                  placeholder="Email"
                  @change="errors.clear('email')"
                />
              </el-col>
            </el-form-item>
          </el-col>
          <pz-check-form />
          <el-col
            :span="24"
            class="center-block text-center"
          >
            <pz-back-button
              v-if="producto.showBack"
              :button-name="'Simular'"
            />
            <pz-forward-button />
          </el-col>
        </el-form>
      </el-col>
    </el-row>
  </el-col>
</template>

<script>


    import {Cedula, required} from "@/commons/utils/Rules";
    import 'element-ui/lib/theme-chalk/display.css'
    import {getDepartamentos} from '@/routes/api/resources';
    import baseForm1 from '@/components/Flujo/Formularios/Formularios1/baseForm1';
    import PzBackButton from "@/commons/forms/Buttons/PzBackButton";
    import PzForwardButton from "@/commons/forms/Buttons/PzForwardButton";
    import PzCheckForm from "@/components/Flujo/Formularios/Formularios1/CheckFields/ChecksForm";

    export default {
        name: 'PzCompanyForm1Mx',
        components: {PzCheckForm, PzForwardButton, PzBackButton},
        mixins: [baseForm1],
        data() {

            return {
                loading: false,
                marital_status: [],
                centerDialogVisible: false,
                departamentos: [],
                formulario1: {
                    nombres: '',
                    apellidos: '',
                    numero_documento: '',
                    provincia: '',
                    telefono: '',
                    email: '',
                    tipo_documento: 1,

                },
                disable: true,
                rules_formulario1: {
                    nombres:
                        [
                            required('')
                        ],
                    apellidos:
                        [
                            required('')
                        ],
                    provincia:
                        [
                            required('')
                        ],
                    numero_documento:
                        [
                            Cedula(''),
                        ],
                    telefono:
                        [
                            required('')

                        ],


                },
            };
        },

        created() {
            getDepartamentos().then((res) => {
                this.departamentos = res.data;

            }).catch(() => {
                this.departamentos = [];

            });

        },

        mounted() {

        },

        methods: {}


    }


</script>

