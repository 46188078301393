<template>
  <pz-form-flujo
    v-model="formulario1['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form1_persona.MX.title') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario1"
      status-icon
      :rules="rules_formulario1"
    >
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="identity.number"
            :label="$t('forms_flujo.form1_persona.MX.identity_doc')"
            :error="errors.get('numero_documento')"
          >
            <el-input
              ref="identity.number"
              v-model="formulario1.identity.number"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.MX.identity_doc')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="telefono"
            :label="$t('forms_flujo.form1_persona.MX.telefono')"
            :error="errors.get('telefono')"
          >
            <el-input
              ref="telefono"
              v-model="formulario1.telefono"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.MX.telefono')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="estado_civil_id"
            :label="$t('forms_flujo.form1_persona.MX.civil_state')"
            :error="errors.get('estado_civil_id')"
          >
            <el-select
              ref="estado_civil_id"
              v-model="formulario1.estado_civil_id"
              clearable
              :placeholder="$t('forms_flujo.form1_persona.MX.civil_state')"
            >
              <el-option
                v-for="item in marital_status"
                :key="item.id"
                :label="item.estado"
                :value="item.id"
                :placeholder="$t('forms_flujo.form1_persona.MX.civil_state')"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="cuota_maxima"
            :label="$t('forms_flujo.form1_persona.MX.cuota_maxima')"
            :error="errors.get('cuota_maxima')"
          >
            <el-input
              ref="cuota_maxima"
              v-model.number="formulario1.cuota_maxima"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.MX.cuota_maxima')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="fecha_ingreso"
            :label="$t('forms_flujo.form1_persona.MX.fecha_ingreso')"
            :error="errors.get('fecha_ingreso')"
          >
            <el-date-picker
              v-model="formulario1.fecha_ingreso"
              type="date"
              :picker-options="pickerOptions1"
              :disabled="!(!formulario1.jubilado)"
              format="dd/MM/yyyy"
              value-format="dd/MM/yyyy"
              :placeholder="$t('forms_flujo.form1_persona.MX.fecha_ingreso')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="destino_prestamo"
            :label="$t('forms_flujo.form1_persona.MX.destino_prestamo')"
            :error="errors.get('destino_prestamo')"
          >
            <el-input
              ref="destino_prestamo"
              v-model="formulario1.destino_prestamo"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.MX.destino_prestamo')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="ingreso_mensual"
            :label="$t('forms_flujo.form1_persona.MX.ingreso_mensual')"
            :error="errors.get('ingreso_mensual')"
          >
            <el-input
              ref="ingreso_mensual"
              v-model.number="formulario1.ingreso_mensual"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.MX.ingreso_mensual')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <pz-check-form />
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <pz-terms-and-conditions
            v-model="formulario1.termYCond"
            prop="termYCond"
            :default="formulario1.termYCond"
          >
            <div>{{ producto.termsAndConditions }}</div>
          </pz-terms-and-conditions>
        </el-col>
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>

<script>

    import {required, type} from "@/commons/utils/Rules";
    import {getEstadoCivil} from "@/routes/api/resources";
    import 'element-ui/lib/theme-chalk/display.css'
    import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
    import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
    import PzCheckForm from "@/components/Flujo/Formularios/Formularios1/CheckFields/ChecksForm";
    import PzTermsAndConditions from "@/commons/forms/Fields/PzTermsAndConditions";


    export default {
        name: 'PzPersonForm1Mx',
        components: {PzTermsAndConditions, PzCheckForm, PzFormFlujo,},
        mixins: [PzBaseForm1],
        data() {


            return {
                marital_status: [],
                formulario1: {
                    telefono: '',
                    estado_civil_id: '',
                    fecha_ingreso: '',
                    cuota_maxima: '',
                    ingreso_mensual: '',
                    destino_prestamo: '',
                    clearing: '',
                    identity: {
                        document: 1,
                        number: ''
                    },
                    comprobante_domicilio:null,
                    comprobante_ingresos:null
                },

                rules_formulario1: {
                    'identity.number':
                        [
                            required(''),

                        ],
                    telefono:
                        [
                            required('')

                        ],
                    estado_civil_id:
                        [
                            required('')

                        ],
                    fecha_ingreso:
                        [
                            required('')

                        ],

                    cuota_maxima:
                        [
                            required(''),
                            type({field: '', type: 'number'}),
                        ],
                    ingreso_mensual:
                        [
                            required(''),
                            type({field: '', type: 'number'}),
                        ],
                    destino_prestamo:
                        [
                            required('')

                        ],


                },
                pickerOptions1: {
                    disabledDate(time) {
                        return Date.now() < time.getTime();
                    },
                },
            };

        },

        created: function () {
            getEstadoCivil().then((res) => {
                this.marital_status = res.data;

            }).catch(() => {
                this.marital_status = [];

            });
        },
        mounted() {

        },

        methods: {}


    }


</script>

